import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import emptyImg from "../assets/empty.jpeg";
import { reduxFunctions } from "../helper/ReduxHelper";
import { Link, useHistory } from "react-router-dom";
import { Avatar, Button, Image, Table } from "antd";
import Empty from "../assets/svgs/empty.svg";
import { toast } from "react-toastify";

const AvailableDrivers = (props) => {
  const [data, setData] = useState([]);

  let history = useHistory();

  useEffect(() => {
    props
      .getCompanyAvailableDrivers(props.match.params.id)
      .then((res) => {
        const newData = [];
        res.payload.data.drivers?.forEach((driver) => {
          newData.push({ ...driver, image: driver.profile_picture?.original });
        });
        setData(newData);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const handleTripUpdate = (driver_id, id, status) => {
    props
      .updateTripStatus({ driver_id, id, status })
      .then((res) => {
        toast.success("Trip assigned successfully");
        history.push("/app/trips/upcoming-trips");
      })
      .catch((error) => {
        toast.error(
          `${error?.error?.response?.data?.message || "An error occurred"}`
        );
        return error;
      });
  };

  const EmptyIcon = (
    <div>
      <img src={Empty} alt="Empty list" style={{ width: "100px" }} />
      <p>There is no available driver with the required truck type</p>
    </div>
  );

  const availableDriversColumn = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      // responsive: ['md'],
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            shape="square"
            src={text ? <Image src={text} /> : <Image src={emptyImg} />}
          />
          <Link
            style={{ fontWeight: "bolder", fontSize: "10px" }}
            to={`/app/driver/${record._id}`}
          >
            View driver details
          </Link>
        </div>
      ),
    },
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "full_name",
      // responsive: ['md'],
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "phone_number",
      responsive: ["md"],
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "assign_truck",
      key: "assign_truck",
      render: (text, record) => (
        <Button
          className="all-button"
          type="primary"
          size={"large"}
          onClick={() =>
            handleTripUpdate(record._id, props.match.params.id, "ACCEPTED")
          }
        >
          Assign
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h1 className="header">Available Driver</h1>
      <div className="containers available-drivers">
        <Table
          className="table"
          locale={{ emptyText: EmptyIcon }}
          columns={availableDriversColumn}
          dataSource={data}
          size="large"
          pagination={false}
        />
      </div>
      {/*{loading ? <div className='center'>*/}
      {/*        <Spin size="large"/>*/}
      {/*    </div> :*/}
      {/*    data?.length > 0 ? <div className='containers available-drivers'>{data.map(driver => <DriverItem*/}
      {/*            data={driver} key={driver._id} handleTripUpdate={handleTripUpdate} tripId ={props.match.params.id} />)} </div>:*/}
      {/*        <div style={{background: "white", height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
      {/*            <img src={Empty} alt='Empty list' style={{ width: '100px' }}/>*/}
      {/*        </div>}*/}
    </div>
  );
};

const mapStateToProps = ({ user, available_drivers }) => ({
  user,
  available_drivers,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(AvailableDrivers);
