import React, { useState } from "react";
import { connect } from "react-redux";
import { Avatar, Button, Image } from "antd";
import { reduxFunctions } from "../../helper/ReduxHelper";
import "../../styles/main.css";
import moment from "moment";
import { getProgress } from "../../helper/TripHelper";
import GoMap from "../common/GoMap";
import { TRIP_STATE, VEHICLE_TYPES } from "../../constants/Enums";
import { formatMoney, getKeyByValue } from "../../helper/UtilityHelper";
import { toast } from "react-toastify";
import PlaceBidModal from "./PlaceBidModal";
import { authenticatedUser } from "../../helper/AuthHelper";
import refreshIcon from "../../assets/svgs/refresh-icon.svg";
import styled from "styled-components";

const TripItem = ({ data, create_bid_loading, createBid, getBids, bid_loading }) => {
  const [newBidData, setNewBidData] = useState({ bid_price: "", driver_id: "" });
  const [showMap, setShowMap] = useState(false);
  // eslint-disable-next-line
  const [truckLocation, setTruckLocation] = useState({});
  // eslint-disable-next-line
  const [tripStatus, setTripStatus] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const userDetails = authenticatedUser();
  const isStandAloneDriver = userDetails?.user_type === "driver" && !userDetails?.is_employee;

  const mapToggle = () => {
    setShowMap(!showMap);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const placeNewBid = async () => {
    if (!newBidData?.driver_id && !isStandAloneDriver) return toast.error("Please assign a driver");
    if (!newBidData?.bid_price) return toast.error("Please enter bid price");

    await createBid({ id: data?._id, bid_price: newBidData?.bid_price, driver_id: newBidData?.driver_id })
      .then((res) => {
        toast.success("Bid placed successfully");
        getBids();
        closeModal();
      })
      .catch((error) => {
        toast.error(error.error.response.data.message);
        return error;
      });
  };

  const isBidding = !!data?.bids?.[0]?.bid_price;

  const tripIsPending = data?.state.value === TRIP_STATE.PENDING;

  const startedBidding = data?.bids?.filter((e) => e.bid_creator === userDetails?._id)?.length > 0 ? true : false;

  return (
    <>
      <div className="trip-item-container">
        <div className="second-trip-item">
          <div className="div-item alt">
            <div>
              <h1>{getProgress(data?.state.value).title}</h1>
              <p>{moment(`${data.start_time}`).format("MMMM Do YYYY, h:mmA")}</p>
            </div>
            <div className="tdo">
              <p>TDO IMAGE</p>
              <Avatar shape="square" size={"default"} src={<Image src={data.tdo?.tdo_image} />} />
            </div>
            <p
              onClick={() => mapToggle(data._id)}
              style={{
                textDecoration: "underline",
                color: "#f77120",
                marginTop: "3vh",
                marginBottom: "3vh",
                cursor: "pointer",
              }}
              className="all-button"
              type="primary"
              size={"small"}
            >
              {!showMap ? "View Map" : "Close Map"}
            </p>
          </div>
          <div className="second-item">
            <p>From</p>
            <h1>{data.start_address}</h1>
            <p>To</p>
            <h1>{data.end_address}</h1>
            <div className="sub-div">
              {data.container_number && (
                <div>
                  <p>Container ID</p>
                  <p>{data.container_number}</p>
                </div>
              )}
              <div>
                <p>Vehicle Type</p>
                <p>{getKeyByValue(VEHICLE_TYPES, data.item_size)}</p>
              </div>
              <div>
                <p>Offload Duration</p>
                <p>{moment.duration(data.offloading_duration, "milliseconds").asDays()}days</p>
              </div>
              <div>
                <p>Tonnage</p>
                <p>{data.tonnage} tonnes</p>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
              {isBidding ? (
                !startedBidding && (
                  <div>
                    <p>Price</p>
                    <h3 style={{ fontWeight: "600" }}>{formatMoney({ value: data?.cost_id?.price, money: true })}</h3>
                  </div>
                )
              ) : (
                <div>
                  <p>Estimated Price</p>
                  <h3 style={{ fontWeight: "600" }}>
                    {formatMoney({ value: data?.cost_id?.price, money: true })} -{" "}
                    {formatMoney({ value: data?.cost_id?.price_upper_bound, money: true })}
                  </h3>
                </div>
              )}
            </div>
          </div>
          {tripIsPending && (
            <ButtonContainer className="flex items-center gap-4">
              <Button className="all-button" type="primary" size={"large"} onClick={() => setOpenModal(true)}>
                Place New Bid
              </Button>

              <img
                src={refreshIcon}
                alt="refresh icon"
                title="Refresh Bids"
                className={`${bid_loading ? "animate-spin" : ""}`}
                onClick={getBids}
              />
            </ButtonContainer>
          )}
        </div>

        {showMap && (
          <div style={{ padding: "0 30px 20px" }}>
            <GoMap
              className="upcoming-item-map"
              data={data}
              truckLocation={truckLocation}
              setTripStatus={setTripStatus}
            />
          </div>
        )}
      </div>

      <PlaceBidModal
        title="Place Your Bid"
        description="Submit your bid now and assign an available driver for the upcoming shipment"
        visible={openModal}
        data={newBidData}
        loading={create_bid_loading}
        setData={setNewBidData}
        closeModal={closeModal}
        handleSubmit={placeNewBid}
        tripId={data?._id}
      />
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  img {
    max-height: 16px;
    cursor: pointer;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .animate-spin {
    animation: spin 1s linear infinite;
  }
`;

const mapStateToProps = ({ user, ongoing_trips, loadingTripUpdate, create_bid_loading, bid_loading }) => ({
  user,
  ongoing_trips,
  loadingTripUpdate,
  create_bid_loading,
  bid_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TripItem);
