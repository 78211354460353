import React, { useEffect } from "react";
import styled from "styled-components";
import { formatMoney } from "../../helper/UtilityHelper";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { authenticatedCompany } from "../../helper/AuthHelper";

const MetricsContainer = (props) => {
  const company = authenticatedCompany();

  const metrics = props?.metrics;

  const totalTrips =
    metrics?.completedTripsCount +
      metrics?.upcomingTripsCount +
      metrics?.liveTripsCount || 0;

  const data = [
    {
      name: "Total Trips",
      value: totalTrips || 0,
      icon: "/total-trips-icon.svg",
    },
    {
      name: "Completed Trips",
      value: metrics?.completedTripsCount || 0,
      icon: "/completed-trips-icon.svg",
    },
    {
      name: "Upcoming Trips",
      value: metrics?.upcomingTripsCount || 0,
      icon: "/upcoming-trips-icon.svg",
    },
    {
      name: "Live Trips",
      value: metrics?.liveTripsCount || 0,
      icon: "/live-trips-icon.svg",
    },
    {
      name: "Pending Bids",
      value: metrics?.pendingBids || 0,
      icon: "/pending-bids-icon.svg",
    },
    {
      name: "Flagged Trips",
      value: metrics?.flaggedTripsCount || 0,
      icon: "/flagged-trips-icon.svg",
    },
    {
      name: "Active Trucks",
      value: metrics?.activeTrucks || 0,
      icon: "/active-trucks-icon.svg",
    },
    {
      name: "Drivers",
      value: metrics?.drivers || 0,
      icon: "/drivers-icon.svg",
    },
  ];

  const fetchMetrics = async () => {
    try {
      const response = await props.getMetrics(company?._id);
      if (response?.error) {
        throw new Error(response);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    company?._id && fetchMetrics();
  }, [company?._id]);

  return (
    <Container>
      {data?.map(({ name, value, icon }) => (
        <div key={name} className="card">
          <div>
            <h3>{name}</h3>
            <p>{formatMoney({ value })}</p>
          </div>

          <img src={icon} alt={name} />
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  background: #fefefe;
  padding: 16px;
  margin-bottom: 20px;
  gap: 16px;
  border-radius: 8px;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  .card {
    padding: 12px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 6px 6px 54px 0px #0000000d;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    color: #202224;

    h3 {
      font-size: 14px;
      font-weight: 300;
      margin: 0;
    }

    p {
      font-size: 28px;
      font-weight: bold;
      margin: 0;
    }

    img {
      max-width: 50px;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const mapStateToProps = ({ metrics }) => ({ metrics });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(MetricsContainer);
