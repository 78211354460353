import styled from "styled-components";
import { Button, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import CustomSelect from "../common/Select";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { authenticatedUser } from "../../helper/AuthHelper";

const PlaceBidModal = ({
  visible,
  closeModal,
  handleSubmit,
  loading,
  title,
  description,
  setData,
  data,
  getCompanyAvailableDrivers,
  tripId,
  accept,
}) => {
  const [drivers, setDriverData] = useState([]);

  const userDetails = authenticatedUser();
  const isStandAloneDriver = userDetails?.user_type === "driver" && !userDetails?.is_employee;

  const driverOptions = drivers?.map((driver) => {
    return {
      label: `${driver?.full_name} - ${driver?.phone_number}`,
      value: driver?._id,
    };
  });

  const handleSelect = (value) => {
    setData({ ...data, driver_id: value });
  };

  const handleCancel = () => {
    closeModal();
    setData({ bid_price: "", driver_id: "" });
  };

  useEffect(() => {
    if (visible && !isStandAloneDriver) {
      getCompanyAvailableDrivers(tripId)
        .then((res) => {
          const newData = [];
          res.payload.data.drivers?.forEach((driver) => {
            newData.push({ ...driver, image: driver.profile_picture?.original });
          });
          setDriverData(newData);
        })
        .catch((error) => {
          return error;
        });
    }

    if (isStandAloneDriver && visible) {
      setData({ ...data, driver_id: userDetails?._id });
    } //set driver_id to the standalone drivers id
  }, [isStandAloneDriver, visible]);

  return (
    <CustomStyledModal
      visible={visible}
      width={600}
      centered
      onCancel={handleCancel}
      footer={""}
      closable={false}
      maskClosable={false}
    >
      <div className="bid-container lg:p-2">
        <div>
          <h2 className="bid-title">{title}</h2>
          <p className="lg:px-6 bid-description">{description}</p>
        </div>

        <div className="lg:px-3 my-6 lg:my-8 input-details-container">
          {!isStandAloneDriver && (
            <div className="lg:px-3 my-6 lg:my-8 bid-input">
              <label className="bid-label">Assign Driver</label>
              <div className="driver-container">
                <div className="selectContainer2">
                  <CustomSelect
                    options={driverOptions}
                    size="large"
                    handleChange={handleSelect}
                    value={data.driver_id}
                    bordered={false}
                    customStyle={{ width: "100%" }}
                    emptyText="No Available Drivers"
                  />
                </div>
              </div>
            </div>
          )}

          {!accept && (
            <div className="lg:px-3 my-6 lg:my-8 bid-input">
              <label className="bid-label">Enter your bid</label>
              <div className="bid-input-container">
                <span className="font-[500]">N</span>{" "}
                <InputNumber
                  name="cargo_value"
                  value={data?.bid_price}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) => setData({ ...data, bid_price: value })}
                  placeholder="Enter you bid"
                  controls={false}
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "none",
                    border: "none",
                    outline: "none",
                    padding: "2px 0",
                    ":focus": {
                      outline: "none",
                      border: "none",
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex gap-3 items-center w-full lg:px-3 bid-actions-container">
          <Button className="bid-btn" onClick={handleCancel} size={"large"}>
            Cancel
          </Button>
          <Button className="bid-btn" type={"primary"} size={"large"} onClick={handleSubmit}>
            {loading ? (
              <ScaleLoader color={"#ffffff"} loading={loading} size={100} width={5} margin={3} height={15} />
            ) : accept ? (
              "Accept Bid"
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    </CustomStyledModal>
  );
};

const CustomStyledModal = styled(Modal)`
  .border {
    border: 1px solid rgba(102, 112, 133, 0.65);
    border-radius: 4px;
  }

  .bid-container {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center
    padding: 8px
  }

  .bid-title {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    margin: 0;
  }

  .bid-description {
    color: #667085;
    text-align: center;
    margin-bottom: 0;
    padding: 0 1.5rem;
  }

  .bid-input {
    width: 100%;
    margin-bottom: 1.5rem;
    text-align: left;
    padding: 0 0.75rem; 
  }

  .input-details-container {
    width: 100%;
    text-align: left;
    margin: 1rem 0;
    // padding: 0 0.75rem; 
  }

  .bid-label {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .bid-input-container {
    position: relative;
    background: #f7f7f7;
    width: 100%;
    border: 1px solid rgba(102, 112, 133, 0.65);
    border-radius: 5px;
    padding: 0 1rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
  }

  .driver-container {
    width: 100%;
    margin-top: 0.5rem;
  }

  .bid-actions-container {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    width: 100%;
    padding: 0 0.75rem;
  }

  .bid-btn {
    width: 100%
  }
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PlaceBidModal);
