import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Empty from "../assets/svgs/empty.svg";
import { reduxFunctions } from "../helper/ReduxHelper";
import { Table, Pagination, DatePicker, Button } from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Input from "../components/common/Input";
import { pastTripColumns } from "../configs/tableConfig";
import { paginate } from "../utils/paginate";
import "../styles/main.css";
import { CSVLink } from "react-csv";
import currency from "currency.js";

const { RangePicker } = DatePicker;

const PastTrips = (props) => {
  const [displayedData, setDisplayedData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  const getTripData = () => {
    props
      .getPastTrips()
      .then((res) => {
        let newTrips = [];

        if (res.payload.data.trips) {
          res.payload.data.trips.forEach((trip) => {
            const newTrip = {
              id: `${trip._id}`,
              details: `From ${trip.start_address} to ${trip.end_address}`,
              amount: currency(trip.cost_id?.price).format({ symbol: "₦" }),
              date: moment(`${trip.start_time}`).format("DD/MM/YYYY"),
              start_time: trip.start_time,
              status:
                trip.state.value === "FINISHED" ? "Finished" : "Cancelled",
              state: trip.state.value,
            };
            newTrips.push(newTrip);
          });

          setDisplayedData([...newTrips]);
        }
      })
      .catch((error) => {
        // toast.error('Error getting past trip data!');
        return error;
      });
  };

  const filterData = () => {
    let filteredData = props.past_trips.map((trip) => {
      const newTrip = {
        id: `${trip._id}`,
        details: `From ${trip.start_address} to ${trip.end_address}`,
        amount: currency(trip.cost_id?.price).format({ symbol: "₦" }),
        date: moment(`${trip.start_time}`).format("DD/MM/YYYY"),
        start_time: trip.start_time,
        status: trip.state.value === "FINISHED" ? "Paid" : "Declined",
        state: trip.state.value,
      };
      return newTrip;
    });

    if (searchInput) {
      filteredData = filteredData.filter((row) =>
        row.details.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (startDate && endDate) {
      filteredData = filteredData.filter(
        (item) =>
          moment(item.start_time).isSameOrAfter(moment(startDate)) &&
          moment(item.start_time).isSameOrBefore(moment(endDate))
      );
    }

    setPageNumber(1);
    setDisplayedData(filteredData);
  };

  const handleSearchChange = ({ currentTarget: input }) => {
    setSearchInput(input.value);
  };

  const handleDateChange = (date) => {
    if (date) {
      setStartDate(date[0].toDate());
      setEndDate(date[1].toDate());
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  let paginatedData = paginate(displayedData, pageNumber, 10);

  const headers = [
    { label: "ID", key: "id" },
    { label: "Amount", key: "amount" },
    { label: "Trip State", key: "state" },
    { label: "Date", key: "date" },
    { label: "Details", key: "details" },
  ];

  const csvReport = {
    filename: "TripHistory.csv",
    headers: headers,
    data: paginatedData,
  };

  const EmptyIcon = (
    <img src={Empty} alt="Empty list" style={{ width: "100px" }} />
  );

  useEffect(() => {
    filterData();
  }, [searchInput, startDate, endDate, props.past_trips]);

  useEffect(() => {
    getTripData();
  }, []);

  return (
    <div>
      <h2 className="header">Trip History</h2>
      <div className="inner-container">
        <div className="past-trip-top" type="primary">
          <div className="filterContainer">
            <Input
              placeholder="Search"
              size="large"
              value={searchInput}
              className="past-trip-input"
              prefix={<SearchOutlined />}
              handleChange={handleSearchChange}
            />
            <RangePicker className="range-picker" onChange={handleDateChange} />
          </div>
          <CSVLink {...csvReport}>
            <Button className="all-button" type="primary" size={"large"}>
              Export CSV
            </Button>
          </CSVLink>
        </div>
        <div className="table-container">
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  props.history.push(`/app/trip-summary/${record.id}`);
                }, // click row
              };
            }}
            className="table"
            locale={{ emptyText: EmptyIcon }}
            columns={pastTripColumns}
            dataSource={paginatedData}
            size="large"
            pagination={false}
          />

          {displayedData?.length > 10 && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                current={pageNumber}
                onChange={handlePageChange}
                total={displayedData.length}
                style={{ alignSelf: "end" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, trips, past_trips }) => ({
  user,
  trips,
  past_trips,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PastTrips);
