import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Spin } from "antd";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import OtpInput from "react-otp-input";
import "../styles/auth.css";
import loadingIcon from "../components/common/loadingIcon";
import { toast } from "react-toastify";
import AuthLayout from "../components/layout-components/AuthLayout";
import styled from "styled-components";
import { authenticatedUser } from "../helper/AuthHelper";
import { LoadingOutlined } from "@ant-design/icons";

const EmailVerification = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const delay = 300; // 300 seconds = 5 minutes
  // Set initial state for time left and button enabled status
  const [timeLeft, setTimeLeft] = useState(0);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const timerIdRef = useRef(null);

  const history = useHistory();

  const user = authenticatedUser();

  const verifySuccess = () => {
    const updatedUser = { ...user, is_email_or_phone_verified: true };
    localStorage.setItem("user", JSON.stringify(updatedUser));
  };

  const handleSubmit = () => {
    if (otp.length < 6) {
      toast.error("Please enter 6-digit code");
      return;
    }
    setLoading(true);
    props
      .verifyUser(parseInt(otp))
      .then((res) => {
        setLoading(false);
        toast.success("Account verified successfully");
        verifySuccess();
        history.push("/app");
      })
      .catch((error) => {
        toast.error("Invalid code");
        setLoading(false);
        return error;
      });
  };

  const resetTimer = () => {
    clearInterval(timerIdRef.current);
    setTimeLeft(delay);
    setButtonEnabled(false);
    timerIdRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 1) {
          return prevTime - 1;
        } else {
          setButtonEnabled(true);
          clearInterval(timerIdRef.current);
          return 0;
        }
      });
    }, 1000);
  };

  const handleResend = () => {
    props
      .resendVerificationCode()
      .then((res) => {
        toast.success("Code sent successfully");
        resetTimer();
      })
      .catch((error) => {
        toast.error("Error resending code");
        return error;
      });
  };

  useEffect(() => {
    // Function to update the timer
    const tick = () => {
      setTimeLeft((prevTime) => {
        if (prevTime > 1) {
          return prevTime - 1;
        } else {
          setButtonEnabled(true);
          clearInterval(timerIdRef.current);
          return 0;
        }
      });
    };

    // Start the timer
    timerIdRef.current = setInterval(tick, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timerIdRef.current);
  }, []);

  // Function to format time left as mm:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    if (user.is_email_or_phone_verified) return history.push("/app");
  }, [user]); //push verified users away

  return (
    <AuthLayout>
      <Container>
        <h2 className="">Enter Verification Code</h2>
        <div className="">
          <p>Kindly enter the code sent to your email</p>
          <OtpInput
            value={otp}
            onChange={(value) => setOtp(value)}
            numInputs={6}
            separator="-"
            inputStyle="input"
            containerStyle="input-container"
            focusStyle="focus-input"
            shouldAutoFocus
            isInputNum
          />
        </div>
        <Button
          onClick={handleSubmit}
          className="btn"
          block
          type="primary"
          size={"large"}
        >
          {loading ? <Spin indicator={loadingIcon} /> : <span>Verify</span>}
        </Button>
        <p className="resend-otp">
          Didn't get OTP?
          <span
            style={{
              cursor: buttonEnabled ? "pointer" : "not-allowed",
              color: buttonEnabled ? "#e27725" : "",
              textDecoration: buttonEnabled ? "underline" : "none",
            }}
            onClick={
              buttonEnabled && !props.verify_code_loading ? handleResend : null
            }
          >
            Resend{" "}
            {props.verify_code_loading && (
              <LoadingOutlined
                style={{ fontSize: 14, color: "#e27725" }}
                spin
              />
            )}
          </span>{" "}
          {timeLeft > 0 && `in ${formatTime(timeLeft)}`}
        </p>
      </Container>
    </AuthLayout>
  );
};

const Container = styled.div`
  a,
  p {
    font-size: 14px;
  }

  .input {
    border-radius: 4px;
    margin: 0px 10px;
    font-color: #000;
    font-size: 18px;
    width: 2.5rem !important;
    height: 2.5rem !important;
    border: 1px solid #ccc !important;
  }

  .input-container {
    display: flex;
    justify-content: center;
    margin: 40px auto;
  }

  .focus-input {
    outline: 1px solid #e27725;
  }

  .resend-otp {
    margin-top: 10px;

    span {
      margin-left: 5px;
    }
  }

  .check {
    display: flex;
    gap: 10px;
  }

  .btn {
    padding: 12px;
    height: 3rem;
    border-radius: 8px;
  }
`;

const mapStateToProps = ({ user, verify_code_loading }) => ({
  user,
  verify_code_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
