import moment from "moment";
import currency from "currency.js";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import React from "react";
let _ = require("underscore");

export function sum(a, b) {
  return a + b;
}

export function revenuePerDay(trips) {
  let grouped = _.groupBy(trips, function (num) {
    return moment(num.start_time).format("MMMM Do YYYY");
  });

  let result = _.map(grouped, function (num) {
    let data = {
      x: moment(num[0].start_time).valueOf(),
      y: _.reduce(
        num,
        function (memo, val) {
          return currency(memo).add(val.price).value;
        },
        0
      ),
    };
    return data;
  });

  return result;
}

export function revenuePerWeek(trips) {
  let grouped = _.groupBy(trips, function (num) {
    return moment(num.start_time).week();
  });

  let result = _.map(grouped, function (num, key) {
    let data = {
      date: moment().week(key).format("DD MMMM YYYY"),
      amount: _.reduce(
        num,
        function (memo, val) {
          return currency(memo).add(val.price).value;
        },
        0
      ),
    };
    return data;
  });
  return result;
}

export function revenuePerMonth(trips) {
  let grouped = _.groupBy(trips, function (num) {
    return moment(num.start_time).month();
  });

  let result = _.map(grouped, function (num, key) {
    let data = {
      date: moment().month(key).format("MMMM YYYY"),
      amount: _.reduce(
        num,
        function (memo, val) {
          return currency(memo).add(val.price).value;
        },
        0
      ),
    };
    return data;
  });
  return result;
}

export function revenuePerYear(trips) {
  let grouped = _.groupBy(trips, function (num) {
    return moment(num.start_time).year();
  });

  let result = _.map(grouped, function (num, key) {
    let data = {
      date: moment().year(key).format("YYYY"),
      amount: _.reduce(
        num,
        function (memo, val) {
          return currency(memo).add(val.price).value;
        },
        0
      ),
    };
    return data;
  });
  return result;
}

export function tripPerWeek(trips) {
  let grouped = _.groupBy(trips, function (num) {
    return moment(num.start_time).week();
  });

  let result = _.map(grouped, function (num, key) {
    let data = {
      x: moment().week(key).valueOf(),
      y: num.length,
    };
    return data;
  });
  return result;
}

export function distancePerDay(trips) {
  let grouped = _.groupBy(trips, function (num) {
    return moment(num.start_time).format("MMMM Do YYYY");
  });

  let result = _.map(grouped, function (num, key) {
    let data = {
      x: moment(num[0].start_time).valueOf(),
      y: _.reduce(
        num,
        function (memo, val) {
          return memo + val.distance;
        },
        0
      ),
    };
    return data;
  });
  return result;
}

export const revenuePercentage = (previous, current) => {
  if (!previous || !current) {
    return {
      info: "Growth or drop % unavailable",
      growth: <InfoCircleOutlined />,
      loss: false,
    };
  } else {
    let value = 0;
    const x = (current - previous) / previous;
    if (x < 0) {
      value = (x * -1 * 100).toFixed(2);
      return {
        info: `${value}%`,
        growth: <ArrowDownOutlined />,
        loss: true,
      };
    } else {
      value = (x * 100).toFixed(2);
      return {
        info: `${value}%`,
        growth: <ArrowUpOutlined />,
        loss: false,
      };
    }
  }
};

export const revenueByDateRange = (data, dateRange) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  let startDate, endDate;

  if (dateRange && dateRange[0] && dateRange[1]) {
    startDate = new Date(dateRange[0]);
    endDate = new Date(dateRange[1]);
  } else {
    startDate = new Date(currentYear, 0, 1); // January 1st of the current year
    endDate = currentDate;
  }

  const filteredData = data
    .filter((item) => {
      const itemDate = new Date(item.start_time);
      return itemDate >= startDate && itemDate <= endDate;
    })
    .map((item) => ({
      date: moment(item.start_time).format("DD MMMM YYYY"),
      amount: item.price,
    }));

  const summedData = filteredData.reduce((acc, item) => {
    const found = acc.find((d) => d.date === item.date);
    if (found) {
      found.amount += item.amount;
    } else {
      acc.push({ date: item.date, amount: item.amount });
    }
    return acc;
  }, []);

  if (summedData.length === 0) {
    for (
      let date = startDate;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      summedData.push({
        date: moment(date).format("DD MMMM YYYY"),
        amount: 0,
      });
    }
  }

  summedData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return summedData.reverse();
};
