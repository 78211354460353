import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import NotificationBar from "./NotificationBar";
import { authenticatedUser } from "../../helper/AuthHelper";

const ProtectedRoute = ({
  path,
  loading,
  component: Component,
  render,
  user,
  ...rest
}) => {
  const [userData, setUserData] = useState(authenticatedUser());
  const [licenseUploaded, setlicenseUploaded] = useState(true);

  const userType = userData?.user_type;

  const isLicenseComplete =
    userData?.drivers_license?.license_number &&
    userData?.drivers_license?.license_image &&
    userData?.drivers_license?.expiry_date
      ? true
      : false;

  useEffect(() => {
    if (!isLicenseComplete) {
      setlicenseUploaded(false);
    } else {
      setlicenseUploaded(true);
    }
  }, [isLicenseComplete]);
  useEffect(() => {
    setUserData(authenticatedUser());
  }, [loading]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userData) return <Redirect to="/login" />;
        if (!userData?.is_email_or_phone_verified)
          return <Redirect to="/verify" />;

        return Component ? (
          <>
            {userType === "driver" && !licenseUploaded && <NotificationBar />}
            <Component {...props} />
          </>
        ) : (
          render(props)
        );
      }}
    />
  );
};

const mapStateToProps = ({ user, loading }) => ({ user, loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
