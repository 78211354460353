import React from "react";
import { Select } from "antd";
const { Option } = Select;

const CustomSelect = ({
  options,
  className,
  size,
  bordered,
  value,
  handleChange,
  disabled,
  customStyle,
  emptyText,
}) => {
  return (
    <Select
      onChange={handleChange}
      defaultValue={value}
      value={value}
      className={className}
      size={size}
      bordered={bordered}
      disabled={disabled}
      style={{ ...customStyle }}
      notFoundContent={emptyText}
    >
      {options.map((opt) => (
        <Option value={opt.value} key={opt.value}>
          {opt.title || opt.label || opt.value}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
