import React, { useEffect, useState } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import currency from "currency.js";
import LoadingState from "../../components/util-components/LoadingState";

Highcharts.setOptions({
  global: {
    useUTC: false,
  },
});

const DashboardCard = ({
  data,
  info,
  number,
  head,
  unit,
  revenue,
  title,
  loading,
}) => {
  // const [chartData, setChartData] = useState([{ x: 0, y: new Date() }]);

  const toolTipFormat = (value) => {
    switch (title) {
      case "distance":
        return `${value} KM`;
      case "trips":
        return `${value} trips`;
      case "revenue":
        return currency(value).format({ symbol: unit });
      default:
        return value;
    }
  };

  const colors = ["#E27626"];
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "spline",
    },
    title: {
      text: "",
    },
    plotOptions: {},
    colors: colors,
    yAxis: {
      labels: {
        formatter: function () {
          return revenue
            ? currency(this.value).format({ symbol: unit })
            : this.value;
        },
      },
    },
    tooltip: {
      pointFormatter: function () {
        let value = toolTipFormat(this.y);
        return (
          '<span style="color:' +
          this.series.color +
          '">' +
          this.series.name +
          "</span>: <b>" +
          value +
          "</b><br />"
        );
      },
      xDateFormat: "%Y-%m-%d",
      shared: true,
    },
    xAxis: {
      title: {
        text: "Date",
      },
      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%d/%m/%y", this.value);
        },
        rotation: 0,
      },

      crosshair: true,

      type: "datetime",
    },
    series: [
      {
        name: head,
        marker: {
          enabled: false,
        },
        data: data,
      },
    ],
  });

  useEffect(() => {
    if (data.length > 0) {
      setChartOptions({
        series: [
          {
            data: data,
          },
        ],
      });
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "410px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            background: "white",
            borderRadius: "8px",
          }}
        >
          <LoadingState />
        </div>
      ) : (
        <div
          style={{
            background: "white",
            borderRadius: "8px",
            padding: "12px 8px",
          }}
        >
          <div className="dashboard-card-header">
            <h2>{number}</h2>
            <p>{info}</p>
          </div>
          {/*<CustomLine config={config} height='100px'/>*/}
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            updateArgs={[true]}
            oneToOne={true}
            allowChartUpdate={true}
          />
        </div>
      )}
    </>
  );
};

export default DashboardCard;
