import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { Spin } from "antd";
import TripItem from "../../components/TripItem";
import Empty from "../../assets/svgs/empty.svg";
import "../../styles/main.css";
import { toast } from "react-toastify";
import { authenticatedUser } from "../../helper/AuthHelper";
import styled from "styled-components";
import { BiMap } from "react-icons/bi";
import LocationInput from "../../components/available-trips/LocationInput";

const TripsWithPendingBids = (props) => {
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [nearBy, setNearBy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState();

  const userDetails = authenticatedUser();
  const isDriver = userDetails?.user_type === "driver";

  const coordinates = { lat: location?.latitude || "", lng: location?.longitude || "" };

  const getTrips = (coord) => {
    setLoading(true);
    props
      .getAvailableTrips({ coordinates: coord ? coord : coordinates, bid_only: true })
      .then((res) => {
        setData(res.payload.data.trips);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return error;
      });
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation(position.coords);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              toast.info("Please enable location to be able to see available trips");
              break;
            case error.POSITION_UNAVAILABLE:
              toast.error("Location information is unavailable");
              break;
            case error.TIMEOUT:
              toast.error("The request to get user location timed out");
              break;
            default:
              toast.error("An unknown error occurred");
              break;
          }
        }
      );
    }
  }

  useEffect(() => {
    if (isDriver) {
      getLocation();
    }
  }, [isDriver]);

  useEffect(() => {
    if (location) {
      getTrips();
    }
  }, [location]);

  useEffect(() => {
    if (!isDriver) {
      getTrips();
    }
  }, []);

  return (
    <div style={{ minHeight: "80vh" }}>
      <Title>
        <h2 className="header">Pending Bids</h2>

        {!isDriver && (
          <div className="inner-box">
            {isFiltering && (
              <p
                className="clear"
                onClick={() => {
                  setIsFiltering(false);
                  getTrips({ lat: "", lng: "" });
                  setNearBy(false);
                }}
              >
                Reset Filter
              </p>
            )}
            <div className="search">
              <LocationInput
                placeholder="Search by location"
                setLocation={setLocation}
                isFiltering={isFiltering}
                setIsFiltering={setIsFiltering}
                inputValue={inputValue}
                setInputValue={setInputValue}
                setNearBy={setNearBy}
              />
            </div>
            <NearBy
              nearBy={nearBy}
              className="nearby"
              onClick={() => {
                getLocation();
                setIsFiltering(true);
                setInputValue("");
                setNearBy(true);
              }}
            >
              <BiMap className="location-icon" /> Near me
            </NearBy>
          </div>
        )}
      </Title>

      {loading ? (
        <div className="center" style={{ marginTop: "24px" }}>
          <Spin size="large" />
        </div>
      ) : data?.length > 0 ? (
        data.map((trip) => <TripItem data={trip} key={trip._id} live={true} available />)
      ) : (
        <div
          style={{
            background: "white",
            height: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/*<h2>No Live trip yet!</h2>*/}
          <img src={Empty} alt="Empty list" style={{ width: "100px" }} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, ongoing_trips, available_trips }) => ({ user, ongoing_trips, available_trips });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TripsWithPendingBids);

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: start;
  }

  .inner-box {
    display: flex;
    gap: 18px;
    align-items: center;

    @media (max-width: 380px) {
      flex-direction: column;
      align-items: start;
      gap: 10px;
    }
  }

  .clear {
    color: red;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const NearBy = styled.button`
  border: 1px solid #667085bf;
  padding: 12px 24px;
  border-radius: 10px;
  color: #e27626;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 15px;
  transition: all 0.3s;
  cursor: pointer;
  background-color: ${(props) => (props.nearBy ? "#fff" : "none")};

  @media (max-width: 400px) {
    padding: 12px 16px;
  }

  .location-icon {
    font-size: 17px;
  }

  &:hover {
    background-color: #fff;
  }
`;
