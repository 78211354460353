import React from "react";
import { Column } from "@ant-design/charts";

const CustomColumn = (props) => {
  const config = {
    data: props.data,
    xField: "date",
    yField: "amount",
    color: "#E27626",
    // label: {
    //     position: 'middle',
    //     style: {
    //         fill: '#FFFFFF',
    //         opacity: 0.6,
    //     },
    // },
    // meta: {
    //     type: { alias: '类别' },
    //     sales: { alias: '销售额' },
    // },
  };

  return (
    <Column
      style={{ minHeight: "300px" }}
      {...config}
      // onReady={(plot) => {
      //     plot.on('plot:click', (evt) => {
      //         const { x, y } = evt;
      //         const { xField } = plot.options;
      //         const tooltipData = plot.chart.getTooltipItems({ x, y });
      //         console.log(tooltipData);
      //     });
      // }}
    />
  );
};

export default CustomColumn;
