import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Row, Col, DatePicker } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import useWindowSize from "../../hooks/useWindowSize";
import "../../styles/main.css";
import CustomColumn from "../../components/common/CustomColumn";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import currency from "currency.js";
import LoadingState from "../../components/util-components/LoadingState";
import { revenueByDateRange } from "../../helper/DataHelper";

const { RangePicker } = DatePicker;

const TotalRevenue = (props) => {
  const [data, setData] = useState([]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1));
  const [endDate, setEndDate] = useState(currentDate);

  const getPaymentHistory = async () => {
    await props.getPaymentHistory();
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  const handleDateChange = (date) => {
    if (date) {
      setStartDate(date[0].toDate());
      setEndDate(date[1].toDate());
    } else {
      setStartDate(new Date(currentYear, 0, 1));
      setEndDate(currentDate);
    }
  };

  useEffect(() => {
    const filteredData = revenueByDateRange(props.paymentHistory, [
      startDate,
      endDate,
    ]);

    setData(filteredData);
  }, [props.paymentHistory, endDate]);

  const formattedData = data?.map((item) => {
    return {
      date: moment(item.date).format("DD/MM/YYYY"),
      amount: currency(item.amount).format({ symbol: "₦" }),
    };
  });

  const headers = [
    { label: "Date", key: "date" },
    { label: "Amount", key: "amount" },
  ];

  const csvReport = {
    filename: "Revenue.csv",
    headers: headers,
    data: formattedData,
  };

  const total = data?.reduce((acc, val) => acc + val.amount, 0);

  const size = useWindowSize();

  return (
    <Row
      className={
        props.margin ? "total-revenue" : " total-revenue revenue-margin"
      }
    >
      {props.loadingPayment ? (
        <LoadingState />
      ) : (
        <>
          <Col span={size.width > 800 ? 6 : 24} className="c1">
            <h2 className="header">Total Revenue</h2>
            {/* <p className="date">{displayDate}</p> */}
            <div className="total-revenue-content">
              <h2 className="header">
                {currency(total).format({ symbol: "₦" })}
              </h2>
              {/* <p className={`p1 ${revenue.loss ? "loss" : "growth"} `}>
                {" "}
                {revenue.growth}
                {revenue.info}
              </p> */}
              <p className="p2">
                Total gross income figure based from the date range given above.
              </p>
            </div>
          </Col>
          <Col span={size.width > 800 ? 18 : 24}>
            <div className="report-container">
              <RangePicker
                style={{
                  borderRadius: " 8px",
                  width: "fit-content",
                  marginRight: "10px",
                }}
                // defaultValue={
                //   startDate && endDate
                //     ? [moment(startDate), moment(endDate)]
                //     : [undefined, undefined]
                // }
                value={[moment(startDate), moment(endDate)]}
                onChange={handleDateChange}
              />

              <CSVLink {...csvReport}>
                <Button
                  icon={<CloudDownloadOutlined />}
                  size={"large"}
                  className="border-radius"
                >
                  <span>Download</span>
                </Button>
              </CSVLink>
            </div>
            <CustomColumn data={data} />
          </Col>
        </>
      )}
    </Row>
  );
};

const mapStateToProps = ({
  user,
  payouts,
  paymentHistory,
  loadingPayment,
}) => ({
  user,
  payouts,
  paymentHistory,
  loadingPayment,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TotalRevenue);
