import React, { useEffect, useState } from "react";
import PendingBidTripItem from "../../components/bidding/PendingBidTripItem";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { Spin } from "antd";
import Empty from "../../assets/svgs/empty.svg";
import BidsTable from "../../components/bidding/BidsTable";
import moment from "moment";
import currency from "currency.js";
import { authenticatedUser } from "../../helper/AuthHelper";
import { TRIP_STATE } from "../../constants/Enums";
import { useHistory } from "react-router-dom";

const PendingBids = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bids, setBids] = useState([]);
  const id = props.match.params.id;
  const personalUser = authenticatedUser();
  const history = useHistory();

  const getTrip = () => {
    setLoading(true);
    props
      .getTrip(id)
      .then(async (res) => {
        setData(res.payload.data.trip);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return error;
      });
  };

  const getBids = async () => {
    try {
      const response = await props.getTripBids(id);

      const bidsArray = response?.payload?.data?.bids?.map((bid) => {
        return {
          date: moment(`${bid?.created_at}`).format("MMMM Do YYYY, h:mma"),
          rate: currency(bid?.bid_price).format({ symbol: "₦" }),
          bid_price: bid?.bid_price,
          id: bid?._id,
          tripId: bid?.trip?._id,
          accepted: bid?.accepted,
          tripState: bid?.trip?.state?.value,
          company: bid?.company_id?.name,
          companyId: bid?.company_id?._id,
          driverId: bid?.driver_id?._id,
          status: bid?.status,
          is_countered: bid?.is_counter,
          creator: bid?.bid_creator._id === personalUser._id ? "You" : "User",
        };
      });

      setBids(bidsArray);
      if (response?.error) {
        throw new Error(response);
      }
    } catch (error) {
      return error;
    }
  };

  // let paginatedTrips = paginate(bids, pageNumber, pageSize);

  useEffect(() => {
    if (id) {
      getTrip();
      getBids();
    }
  }, [id]);

  const tripIsPending = data?.state.value === TRIP_STATE.PENDING;

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={() => history.goBack()}
          style={{ marginBottom: "6px", cursor: "pointer", marginRight: "8px" }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 12H5M5 12L12 19M5 12L12 5"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h2 className="header space-y-2">Bids</h2>
      </div>
      {loading ? (
        <div className="center" style={{ marginTop: "24px" }}>
          <Spin size="large" />
        </div>
      ) : data ? (
        <PendingBidTripItem data={data} available getBids={getBids} />
      ) : (
        <div
          style={{
            background: "white",
            height: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Empty} alt="Empty list" style={{ width: "100px" }} />
        </div>
      )}

      <BidsTable
        bids={bids}
        tripIsPending={tripIsPending}
        tripId={id}
        getBids={getBids}
      />
    </div>
  );
};

const mapStateToProps = ({ user, trip }) => ({ user, trip });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PendingBids);
