import React from "react";
import TotalRevenue from "../Insights/TotalRevenue";
import Payout from "./Payout";
import DailyRevenue from "./DailyRevenue";
import WeeklyTrips from "./WeeklyTrips";
import DailyDis from "./DailyDistance";
import GoMap from "../../components/common/GoMap";
import MetricsContainer from "../../components/dashboard/MetricsContainer";
import styled from "styled-components";

const Dashboard = () => {
  return (
    <div>
      <h2 className="header">Dashboard</h2>

      <MetricsContainer />
      <div className="dashboard-container1">
        <div className="div1">
          <TotalRevenue margin />
        </div>
        <div className="div2">
          <Payout />
        </div>
      </div>

      <ChartContainer>
        <DailyRevenue />

        <WeeklyTrips />

        <DailyDis />
      </ChartContainer>

      <div style={{ padding: "30px", marginTop: "10px", background: "white" }}>
        <GoMap data={{}} height="70" home />
      </div>
    </div>
  );
};

const ChartContainer = styled.div`
  display: grid;
  margin: 20px 0;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export default Dashboard;
