import React, { useState } from "react";
import styled from "styled-components";
import PlaceBidModal from "../bidding/PlaceBidModal";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { BID_DECISION_OPTIONS } from "../../constants/Enums";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { authenticatedUser } from "../../helper/AuthHelper";

const BiddingOptions = (props) => {
  const [data, setData] = useState({ bid_price: "", driver_id: "" });
  const [openDecisionModal, setOpenDecisionModal] = useState(false);

  const history = useHistory();

  const userDetails = authenticatedUser();
  const isStandAloneDriver =
    userDetails?.user_type === "driver" && !userDetails?.is_employee;

  const viewBids = () => {
    history.push(`/app/pending-bids/${props.tripId}`);
  };

  const closeModal = () => {
    setOpenDecisionModal(false);
  };

  const acceptBid = async () => {
    if (!data?.driver_id && !isStandAloneDriver)
      return toast.error("Please assign a driver");

    await props
      .makeBidDecision({
        tripId: props?.tripId,
        bidId: props?.bidId,
        decision: BID_DECISION_OPTIONS.ACCEPT_BID,
        driver_id: data?.driver_id,
      })
      .then((res) => {
        toast.success("Bid accepted successfully");
        closeModal();
      })
      .catch((error) => {
        toast.error(error.error.response.data.message);
        return error;
      });
  };

  return (
    <>
      <ButtonContainer>
        <HoverButton
          className="all-button"
          type="default"
          style={{ color: "#e27626", borderColor: "#e27626" }}
          size="large"
          onClick={viewBids}
        >
          {props.startedBidding ? "View Bids" : "Place Bid"}
        </HoverButton>
        {!props.startedBidding && (
          <Button
            className="all-button"
            type="primary"
            size="large"
            onClick={() => setOpenDecisionModal(true)}
          >
            Accept Bid
          </Button>
        )}
      </ButtonContainer>

      <PlaceBidModal
        title="Accept Bid"
        description=""
        visible={openDecisionModal}
        data={data}
        loading={props.bid_decision_loading}
        setData={setData}
        closeModal={closeModal}
        handleSubmit={acceptBid}
        tripId={props?.tripId}
        accept
      />
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .button {
    font-size: 6px;
  }
`;
const HoverButton = styled(Button)`
  &:hover {
    background-color: rgba(226, 118, 38, 0.1);
    color: #fff;
    border-color: rgba(226, 118, 38, 0.1);
  }
`;

const mapStateToProps = ({ create_bid_loading, bid_decision_loading }) => ({
  create_bid_loading,
  bid_decision_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(BiddingOptions);
