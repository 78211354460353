import React, { useState } from "react";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { Table } from "antd";
import { BID_DECISION_OPTIONS, BID_STATE } from "../../constants/Enums";
import styled from "styled-components";
import BidStatus from "./BidStatus";
import PlaceBidModal from "./PlaceBidModal";
import { toast } from "react-toastify";
import { authenticatedCompany, authenticatedUser } from "../../helper/AuthHelper";

const BidsTable = (props) => {
  const [data, setData] = useState({ bid_price: "", driver_id: "" });
  const [currentBidId, setCurrentBidId] = useState("");
  const [openDecisionModal, setOpenDecisionModal] = useState(false);

  const user = authenticatedCompany();
  const personalUser = authenticatedUser();
  const isCompany = personalUser?.user_type === "company" ? true : false;

  const openAcceptBidModal = (record) => {
    setOpenDecisionModal(true);
    setCurrentBidId(record.id);
  };

  const closeAcceptBidModal = () => {
    setOpenDecisionModal(false);
    setData({ bid_price: "", driver_id: "" });
    setCurrentBidId("");
  };

  const acceptBid = async (bidId) => {
    await props
      .makeBidDecision({
        tripId: props.tripId,
        bidId,
        decision: BID_DECISION_OPTIONS.ACCEPT_BID,
        driver_id: data?.driver_id,
      })
      .then((res) => {
        toast.success("Bid accepted successfully");
        closeAcceptBidModal();
        props.getBids();
      })
      .catch((error) => {
        toast.error(error.error.response.data.message);
        return error;
      });
  };

  const columns = [
    { title: "Bidding Date", dataIndex: "date", key: "date" },
    { title: "Creator", dataIndex: "creator", key: "creator", render: (text) => <p className="creator">{text}</p> },
    { title: "Offer", dataIndex: "rate", key: "rate" },
    {
      title: "Bid Status",
      key: "status",
      dataIndex: "status",
      render: (text) => <BidStatus key={text} status={text} />,
    },
  ];

  if (props.tripIsPending) {
    columns[4] = {
      title: "Action",
      key: "id",
      dataIndex: "id",
      render: (text, record) =>
        record.status === BID_STATE.PENDING &&
        ((isCompany ? record.companyId !== user?._id : record.driverId !== personalUser?._id) ||
          record.is_countered) ? (
          <div className="actions">
            <span className="accept" onClick={() => openAcceptBidModal(record)}>
              Accept Bid
            </span>
          </div>
        ) : (
          "----"
        ),
    };
  }

  return (
    <>
      <Container className="table-container">
        <Table className="table" columns={columns} dataSource={props.bids} size="large" pagination={false} />
      </Container>

      <PlaceBidModal
        title="Accept Bid"
        description="We will notifiy you through email for every carriers' bid. Select a Carrier from the Pending list"
        visible={openDecisionModal}
        data={data}
        loading={props.bid_decision_loading}
        setData={setData}
        closeModal={closeAcceptBidModal}
        handleSubmit={() => acceptBid(currentBidId)}
        tripId={props.tripId}
        accept
      />
    </>
  );
};

const Container = styled.div`
  margin-top: 24px;

  .creator {
    font-weight: 500;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 12px;

    .reject {
      color: #ff3a44;
      cursor: pointer;
      text-decoration: underline;
    }
    .accept {
      color: #58b689;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .ant-table-row {
    cursor: default;
  }
`;

const mapStateToProps = ({ bid_decision_loading }) => ({ bid_decision_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(BidsTable);
